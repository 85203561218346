import React from 'react';

import { CloseIcon } from '../../../../src/assets/images';
import { useHideOverflow } from '../../custom-hooks';

interface Props {
  isOpen: boolean;
  title: string;
  header: string;
  wrapperClassName: string;
  onToggle: () => void;
  onSubmit: (data?: any) => void;
}

function ModalView({
  isOpen,
  title,
  header,
  wrapperClassName,
  onToggle,
  onSubmit,
}: Props) {

  useHideOverflow(isOpen);

  const handleOnSubmit = React.useCallback(() => {
    onSubmit();
    onToggle();
  }, [onSubmit, onToggle]);

  return (
    <div className={wrapperClassName}>
      <div className={`modal-wrapper ${isOpen ? 'active' : ''}`}>
        <div className='modal-inner'>
          <div className='modal-header d-flex flex-center-between'>
            <h6>{title}</h6>
            <button onClick={onToggle}>
              <img src={CloseIcon} alt='Close' />
            </button>
          </div>
          <div className='modal-body'>
            <p>{header}</p>
            <div className='button-wrapper d-flex flex-center'>
              <button
                className='btn secondary-btn small'
                type='reset'
                onClick={onToggle}
              >
                No
              </button>
              <button
                className='btn primary-btn small'
                type='submit'
                onClick={handleOnSubmit}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalView;

import { MODULES } from "../helpers";

// export function getMobileNumber(phoneNumber: string) {
//     if (!phoneNumber) {
//         return {
//             mobileNo: '',
//             countryCode: ''
//         };
//     }
//     const mno: string = String(phoneNumber.replace(/[+]/g, ''));
//     return {
//         mobileNo: mno.substring(mno.length - 10),
//         countryCode: mno.slice(0, -10)
//     };
// };

export function getFullMobileNo({ dialCode, mobileNo }: { dialCode: string; mobileNo: string; }) {
    return `${dialCode} ${mobileNo}`;
};

export function splitMobileNo({ mobileNo, countryCode }: { mobileNo: string; countryCode: string; }) {
    let dialCode = '', mno = '';

    if (!isNaN(parseInt(countryCode))) {
        dialCode = countryCode;
    }

    if (mobileNo) {
        const temp = mobileNo.split(' ');
        if (temp.length === 2) {
            dialCode = temp[0];
            mno = temp[1];
        } else {
            mno = temp[0];
        }
    }

    return { dialCode, mobileNo: mno };
};

export function checkingCountryCode(countryCode: string) {
    if (!countryCode) {
        return '';
    } else if (countryCode === '1') {
        return MODULES.PHONE_NUMBER.DEFAULT_COUNTRY_CODE;
    } else if (countryCode === '91') {
        return 'in';
    } else if (!isNaN(parseInt(countryCode))) {
        return '';
    }
    return countryCode;
};

import axios from "axios";
import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";

import config from '../../../Config';

const customAxios = axios.create({
    baseURL: `${config.CITIES_API_URL}`,
    timeout: 100000,
    headers: {
        'X-RapidAPI-Key': config.RAPID_API_KEY,
        'X-RapidAPI-Host': config.RAPID_API_HOST
    },
});

interface Props {
    disabled?: boolean;
    label?: string;
    labelClass?: string;
    required?: boolean;
    value: any;
    onChange: (event: any) => void;
    onBlur?: () => void;
}

function SearchLocation({ disabled, label, labelClass, required, value, onChange, onBlur }: Props) {

    const handleFetchCities = React.useCallback(async (data?: any) => {
        try {
            const { search, offset = 0 } = data || {};
            const params: any = { offset, limit: 10, languageCode: 'en' };
            if (search) {
                params.namePrefix = search;
            }
            const response = await customAxios.get('/v1/geo/cities', { params });
            if (!!response?.data?.data?.length) {
                return response.data;
            }
            return {};
        } catch (err: any) {
            if (err?.response?.status === 429) {
                return {};
            }
        }
    }, []);

    const loadOptions = async (search: string, prevOptions: any) => {
        let offset = prevOptions.length;
        const searchLower = (search || '').toLowerCase().trim();
        if (searchLower) {
            offset = 0;
        }

        const newOptions = await handleFetchCities({ search: searchLower, offset });
        if (newOptions?.data) {
            const { data, metadata } = newOptions;
            const hasMore = metadata.totalCount > prevOptions.length + data.length;
            return {
                options: newOptions.data,
                hasMore
            };
        } else {
            return {
                options: [],
                hasMore: false
            };
        }
    };

    return (
        <>
            {label &&
                <label className={labelClass} htmlFor='Location'>
                    {label}
                    {required && <sub>*</sub>}
                </label>
            }
            <div className="location-search">
                <div className="search-wrapper react-select">
                    <AsyncPaginate
                        isDisabled={disabled}
                        placeholder='Location'
                        value={value}
                        loadOptions={loadOptions}
                        onChange={onChange}
                        getOptionLabel={(option: any) => option.name}
                        getOptionValue={(option: any) => option.name}
                        onBlur={onBlur}
                        menuPlacement="top"
                        debounceTimeout={1000}
                    />
                </div>
            </div>
        </>
    );
}

SearchLocation.defaultProps = {
    disabled: false,
    label: '',
    labelClass: '',
    required: false,
    onBlur: () => { }
};

export default SearchLocation;

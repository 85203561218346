import Fingerprint2 from 'fingerprintjs2';
import { useEffect, useState } from "react";

export function useFingerprint() {
  const [uniqueId, setUniqueId] = useState<String>('');

  useEffect(() => {
    Fingerprint2.get((components: any[]) => {
      const values = components.map((component: any) => component.value)
      const murmur = Fingerprint2.x64hash128(values.join(''), 31);
      setUniqueId(murmur);
    });
  }, []);

  return uniqueId;
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getDataApi, postDataApi, putDataApi } from '../../api/methods';

interface requestType { baseUrl?: string; path: string; data: any; }

function getRequest(req: requestType) {
    const temp: requestType = Object.assign({}, req);
    temp.baseUrl = '';
    return temp;
}

export const fetchRoles = createAsyncThunk(
    'role',
    (payload: any) => getDataApi(getRequest({ path: 'role', data: payload }))
);

export const updateRole = createAsyncThunk(
    'role',
    (payload: any) => putDataApi(getRequest({ path: 'role', data: payload }))
);

export const addRole = createAsyncThunk(
    'role',
    (payload: any) => postDataApi(getRequest({ path: 'role', data: payload }))
);

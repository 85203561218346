import React from "react";

interface Props {
  children?: React.ReactNode;
  isOpen?: boolean;
  onClose?: any;
  className?: any;
}

function Modal({ children, isOpen, onClose, className }: Props) {
  return (
    <div className={`modal-wrapper  ${isOpen ? "active" : ""}`}>
      <div className={`modal-inner ${className}`}>
        {children}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  className: ''
};

export default Modal;

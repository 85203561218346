interface Props {
    isShow: boolean;
    style: any;
}

function NoRecordFound({ isShow, style }: Props) {

    if (!isShow) {
        return null;
    }

    return <p style={style}>No Record Found</p>;
}

NoRecordFound.defaultProps = {
    isShow: false,
    style: { display: 'flex', justifyContent: 'center' }
};

export default NoRecordFound;

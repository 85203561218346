import React from 'react';
import * as Yup from 'yup';

import { messages, regex, USER_TYPE } from '../../../../helpers';
import { coachResetPassword, userResetPassword } from '../../../../redux/services';
import { useAppDispatch } from '../../../custom-hooks';
import ResetPasswordModalComponent from './Component';
import { detailType, resetPasswordModalValuesType } from './Types';

const Schema = Yup.object().shape({
    password: Yup.string()
        .required(messages.IS_REQUIRED('New Password'))
        .matches(regex.PASSWORD, messages.PASSWORD_VALIDATION),
    confirm_password: Yup.string().when('password', {
        is: (val: any) => val && val.length > 0,
        then: Yup.string().required(messages.IS_REQUIRED('Confirm Password')).oneOf([Yup.ref('password')], messages.CONFIRM_PASSWORD),
    }),
});

interface Props {
    isOpen: boolean;
    userType: string;
    details: detailType;
    onToggle: (type?: string) => void;
}

function ResetPasswordModal({ isOpen, userType, details, onToggle }: Props) {
    const dispatch = useAppDispatch();

    const handleOnSubmit = React.useCallback((
        values: resetPasswordModalValuesType,
        callback: (response: any) => void) => {
        const payload: any = {
            userId: details.id,
            email: details.email,
            newPassword: values.password,
            confirmPassword: values.confirm_password,
            notify: true,
            callback,
        };
        if (userType === USER_TYPE.USER) {
            dispatch(userResetPassword(payload));
        } else if (userType === USER_TYPE.COACH) {
            dispatch(coachResetPassword(payload));
        }
    }, [userType, details.id, details.email, dispatch]);

    return <ResetPasswordModalComponent isOpen={isOpen} schema={Schema} onToggle={onToggle} onSubmit={handleOnSubmit} />;
}

export default ResetPasswordModal;

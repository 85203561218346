import React from "react";

import { Modal } from "..";
import {
    convertBase64ToFile
} from "../../../utils";
import ImageCropper from "./image-cropper";

interface Props {
    onImageUpload: (file: any) => void;
}

function InputFileCropper({ onImageUpload }: Props) {
    const [openCrop, setOpenCrop] = React.useState<boolean>(false);
    const [fileToCrop, setFileToCrop] = React.useState<any>(null);

    const handleFileUpload = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setFileToCrop(e.target.files[0]);
            setOpenCrop(true);
        }
    };

    const handleCloseCropper = () => {
        setOpenCrop(false);
        setFileToCrop(null);
    };

    return <>
        <input
            type="file"
            id="file-input"
            name="file-input"
            onChange={(e: any) => {
                handleFileUpload(e);
            }}
            accept="image/x-png, image/jpg, image/jpeg ,image/svg"
        />
        <Modal
            isOpen={openCrop}
            onClose={handleCloseCropper}
        >
            {fileToCrop && (
                <ImageCropper
                    fileToCrop={URL.createObjectURL(fileToCrop)}
                    onSuccess={(value: any) => {
                        convertBase64ToFile(value).then((file) => {
                            onImageUpload(file);
                            handleCloseCropper();
                        });
                    }}
                    onCancel={handleCloseCropper}
                />
            )}
        </Modal>
    </>;
};

export default InputFileCropper;

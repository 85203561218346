import { createAsyncThunk } from '@reduxjs/toolkit';

import { postDataApi, putDataApi } from '../../api/methods';

export const fetchReviews = createAsyncThunk(
    'admin/ratings',
    (payload: any) => postDataApi({ path: 'admin/ratings', data: payload })
);

export const updateReviewStatus = createAsyncThunk(
    'admin/rating/change-status',
    (payload: any) => putDataApi({ path: "admin/rating/change-status", data: payload })
);

export const addReview = createAsyncThunk(
    'admin/rating/reply',
    (payload: any) => postDataApi({ path: 'admin/rating/reply', data: payload })
);

import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

interface Props {
  fileToCrop: any;
  onSuccess: (data: any) => void;
  onCancel: (data: any) => void;
}

function ImageCropper({ fileToCrop, onSuccess, onCancel }: Props) {
  const [crop, setCrop] = React.useState<any>({
    aspect: 2,
    unit: "px",
    width: 200,
    height: 100,
    x: 0,
    y: 0,
  });
  const [image, setImage] = React.useState<any>(null);

  const cropImageNow = React.useCallback(() => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    onSuccess(base64Image);
  }, [crop, image, onSuccess]);

  return (
    <div className="modal-body text-center">
      {fileToCrop && (
        <>
          <ReactCrop
            src={fileToCrop}
            onImageLoaded={(image: any) => {
              setImage(image);
            }}
            maxHeight={400}
            crop={crop}
            onChange={setCrop}
            ruleOfThirds
            keepSelection={true}
          />
          <div className="button-wrapper d-flex flex-center">
            <button
              className="btn secondary-btn small"
              onClick={onCancel}
              type="button"
            >
              Cancel
            </button>
            <button
              className="btn primary-btn small"
              onClick={cropImageNow}
              type="button"
            >
              Crop
            </button>
          </div>
        </>
      )}
    </div>
  );
};

ImageCropper.defaultProps = {
  onCancel: () => { },
  onSuccess: () => { }
};

export default ImageCropper;

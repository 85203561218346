import { createAsyncThunk } from '@reduxjs/toolkit';

import { getDataApi } from '../../api/methods';

export const fetchDashboardList = createAsyncThunk(
    'admin/dashboard',
    (payload: any) => getDataApi({ path: 'admin/dashboard', data: payload })
);

export const fetchDashboardDiscovers = createAsyncThunk(
    'admin/dashboard-discovers',
    (payload: any) => getDataApi({ path: 'admin/dashboard-discovers', data: payload })
);

import Select from 'react-select';

interface Props {
    isDropdownIndicator?: boolean;
    disabled?: boolean;
    label?: string;
    labelClass?: string;
    placeholder?: string;
    options: Array<any>;
    value: any;
    menuPlacement?: string;
    onSelect: (option: any) => void;
    getOptionLabel?: (option: any) => void;
    getOptionValue?: (option: any) => void;
    onBlur?: () => void;
}

function ReactSelect({ disabled, options, value, label, labelClass, placeholder, isDropdownIndicator, onSelect, onBlur, menuPlacement, getOptionLabel, getOptionValue }: Props) {

    const selectProps: any = {
        options,
        value,
        placeholder,
        menuPlacement,
        onChange: onSelect,
        getOptionLabel,
        getOptionValue,
        onBlur,
        isDisabled: disabled
    };

    if (!isDropdownIndicator) {
        selectProps.components = { DropdownIndicator: () => null, IndicatorSeparator: () => null };
    }

    return (
        <>
            {label && <label className={labelClass} htmlFor={label}>{label}</label>}
            <Select
                {...selectProps}
            />
        </>
    );
}

ReactSelect.defaultProps = {
    disabled: false,
    label: '',
    labelClass: '',
    getOptionLabel: (option: any) => option.label,
    getOptionValue: (option: any) => option.value,
    onBlur: () => { }
};

export default ReactSelect;

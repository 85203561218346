import { DateFormat } from '../common/third-party-instances';

export function startOfDaytime(date: string | number) {
    const start = new Date(date);
    start.setHours(0, 0, 0, 0);
    return start;
}

export function endOfDaytime(date: string | number) {
    const end = new Date(date);
    end.setHours(23, 59, 59, 999);
    return end;
}

export function tableDateFormat(date: Date, format: string = 'd MMM yyyy') {
    return DateFormat(new Date(date), format);
}

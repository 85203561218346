import { useAppSelector } from '../../../custom-hooks';

function DotsLoaderView() {
    const { isTableLoading } = useAppSelector(state => state.common);

    if (!isTableLoading) {
        return null;
    }

    return <tr>
        <td colSpan={8}>
            <div className='dots-loader'>
                <div className="snippet" data-title=".dot-elastic">
                    <div className="stage">
                        <div className="dot-elastic"></div>
                    </div>
                </div>
            </div>
        </td>
    </tr>;
}

export default DotsLoaderView;

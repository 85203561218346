import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommonInitialState } from '../../types';

const initialState: CommonInitialState = {
    isLoading: false,
    isTableLoading: false,
    isPageRefresh: false
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        /** Setting loader state */
        setIsLoader(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setIsTableLoader(state, action: PayloadAction<boolean>) {
            state.isTableLoading = action.payload;
        },
        setIsPageRefresh(state, action: PayloadAction<boolean>) {
            state.isPageRefresh = action.payload;
        },
    },
});

export default commonSlice.reducer;
export const { setIsLoader, setIsTableLoader, setIsPageRefresh } = commonSlice.actions;

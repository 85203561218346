import { createAsyncThunk } from '@reduxjs/toolkit';

import { postDataApi } from '../../api/methods';

export const adminLogin = createAsyncThunk(
    'admin/login',
    (payload: any) => postDataApi({ path: 'admin/login', data: payload })
);

export const adminForgotPassword = createAsyncThunk(
    'admin/forgot-password',
    (payload: any) => postDataApi({ path: 'admin/forgot-password', data: payload })
);

export const adminResetPassword = createAsyncThunk(
    'admin/reset-password',
    (payload: any) => postDataApi({ path: 'admin/reset-password', data: payload })
);

export const adminResendForgotPasswordEmail = createAsyncThunk(
    'admin/resend-Email',
    (payload: any) => postDataApi({ path: 'admin/resend-Email', data: payload })
);

import React from "react";

export function useEditMode() {
    const [isEditMode, setEditMode] = React.useState(false);

    const handleEditMode = React.useCallback(() => {
        setEditMode(pState => !pState);
    }, []);

    return { isEditMode, handleEditMode };
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getDataApi } from '../../api/methods';

export const airtableDataSync = createAsyncThunk(
    'admin/discover/{type}/sync',
    (payload: any) => {
        const request = { ...payload };
        delete request.type;
        return getDataApi({ path: `admin/discover/${payload.type}/sync`, data: request });
    }
);

import { createAsyncThunk } from "@reduxjs/toolkit";

import { getDataApi, putDataApi } from "../../api/methods";

export const fetchInvoices = createAsyncThunk(
  "admin/invoices",
  (payload: any) => getDataApi({ path: "admin/invoices", data: payload })
);
export const fetchHistory = createAsyncThunk(
  "admin/users/activity-history",
  (payload: any) =>
    getDataApi({
      path: "admin/users/activity-history/" + payload.uid,
      data: payload.request,
    })
);

// export const fetchStatus = createAsyncThunk("admin/invoice/change-status", () =>
//   getDataApi({ path: "admin/invoice/change-status" })
// );

export const fetchStatus = createAsyncThunk(
    'admin/invoice/change-status',
    (payload: any) => putDataApi({ path: "admin/invoice/change-status", data: payload })
);

import { useEffect } from "react";

const globalData = {};

function useHandleCalls(fetchData, deps = [], key = 'timer') {
  useEffect(() => {
    if (globalData[key]) {
      clearTimeout(globalData[key]);
    }
    globalData[key] = setTimeout(() => {
      fetchData();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useHandleCalls;

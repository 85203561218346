import { createAsyncThunk } from '@reduxjs/toolkit';

import { postDataApi, getDataApi, putDataApi } from '../../api/methods';

interface requestType { baseUrl?: string; path: string; data: any; }

function getRequest(req: requestType) {
    const temp: requestType = Object.assign({}, req);
    temp.baseUrl = '';
    return temp;
}

export const fetchSubAdmins = createAsyncThunk(
    'subadmin',
    (payload: any) => getDataApi(getRequest({ path: 'subadmin', data: payload }))
);

export const getSubAdmin = createAsyncThunk(
    'subadmin/:cid',
    (payload: any) => getDataApi(getRequest({ path: `subadmin/${payload.subadminId}`, data: payload }))
);

export const updateSubAdminStatus = createAsyncThunk(
    'subadmin/status',
    (payload: any) => putDataApi(getRequest({ path: "subadmin/status", data: payload }))
);

export const addSubAdmin = createAsyncThunk(
    'subadmin',
    (payload: any) => postDataApi(getRequest({ path: 'subadmin', data: payload }))
);

export const updateSubAdmin = createAsyncThunk(
    'subadmin',
    (payload: any) => putDataApi(getRequest({ path: "subadmin", data: payload }))
);

export const convertBase64ToFile = (base64: any) => {
    const filename = new Date().getTime();
    return fetch(base64)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], `${filename}.jpeg`, {
                type: `image/jpeg`,
            });
        })
        .then((res) => {
            return res;
        });
};

export const getBase64FromUrl = async (url: string) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
};

import { Formik } from 'formik';

import { InputField } from '../..';
import {
    CloseIcon,
    Eyeof,
    Eyeon,
    Password
} from '../../../../assets/images';
import { resetPasswordModalValuesType } from './Types';

interface Props {
    isOpen: boolean;
    schema: any;
    onToggle: (type?: string) => void;
    onSubmit: (values: resetPasswordModalValuesType, callback: (response: any) => void) => void;
}

function ResetPasswordModalComponent({ isOpen, schema, onToggle, onSubmit }: Props) {
    return <Formik
        initialValues={{
            password: '',
            confirm_password: '',
            isShowingPassword: false,
            isShowingConfirmPassword: false,
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
            onSubmit(values, (response: any) => {
                resetForm();
                if (response) {
                    onToggle();
                }
            });
        }}
    >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
        }) => (
            <form onSubmit={handleSubmit}>
                <div className='reset-password-modal'>
                    <div className={`modal-wrapper ${isOpen ? 'active' : ''}`}>
                        <div className='modal-inner'>
                            <div className='modal-header d-flex flex-center-between'>
                                <h6>Reset the Password</h6>
                                <button onClick={() => onToggle()}>
                                    <img src={CloseIcon} alt='close' />
                                </button>
                            </div>
                            <div className='modal-body'>
                                <h5>New Password</h5>
                                <form>
                                    <InputField
                                        label='New Password'
                                        className="height password"
                                        type={values.isShowingPassword ? 'text' : 'password'}
                                        name='password'
                                        placeholder="Enter New Password"
                                        value={values.password}
                                        wrapperClass="input-wrapper input-l-icon input-w-icon"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <span
                                            className="input-icon small eye-btn"
                                            onClick={() => setFieldValue('isShowingPassword', !values.isShowingPassword)}
                                        >
                                            <img
                                                src={values.isShowingPassword ? Eyeon : Eyeof}
                                                alt="Eyeoff"
                                                className="eye-off"
                                            />
                                        </span>
                                        <span className='input-pas-icon small left-btn'>
                                            <img src={Password} alt='Password' />
                                        </span>
                                        <span className="validation-error">
                                            {errors.password && touched.password && errors.password}
                                        </span>
                                    </InputField>
                                    <InputField
                                        label='Confirm Password'
                                        className="height password"
                                        type={values.isShowingConfirmPassword ? 'text' : 'password'}
                                        name='confirm_password'
                                        placeholder='Enter Confirm Password'
                                        value={values.confirm_password}
                                        wrapperClass="input-wrapper input-l-icon input-w-icon"
                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                    >
                                        <span
                                            className="input-icon small eye-btn"
                                            onClick={() =>
                                                setFieldValue('isShowingConfirmPassword', !values.isShowingConfirmPassword)
                                            }
                                        >
                                            <img
                                                src={values.isShowingConfirmPassword ? Eyeon : Eyeof}
                                                alt="Eyeoff"
                                                className="eye-off"
                                            />
                                        </span>
                                        <span className='input-pas-icon small left-btn'>
                                            <img src={Password} alt='Password' />
                                        </span>
                                        <span className="validation-error">
                                            {errors.confirm_password &&
                                                touched.confirm_password &&
                                                errors.confirm_password}
                                        </span>
                                    </InputField>
                                </form>
                                <div className='button-wrapper d-flex flex-center'>
                                    <button className='btn primary-btn small' type='submit'>
                                        Change
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )}
    </Formik >;
}

export default ResetPasswordModalComponent;

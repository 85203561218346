import React from "react";

import { DisableStarIcon, FullStarIcon, HalfStarIcon } from "../../../assets/images";

interface Props {
    value: number;
}

function RatingStars({ value }: Props) {

    const list = React.useMemo(() => {
        const starList: Array<number> = [];
        let i = 5;
        let j = value;
        while (i > 0) {
            if (j >= 1) {
                starList.push(1);
            } else if (j > 0) {
                starList.push(0.5);
            } else {
                starList.push(0);
            }
            i--;
            j = j - 1;
        }

        return starList;
    }, [value]);

    return <>
        {list.map((no: number) => {
            return <>
                {no === 1 && <img src={FullStarIcon} alt='full-star' />}
                {no === 0.5 && <img src={HalfStarIcon} alt='half-star' />}
                {no === 0 && <img src={DisableStarIcon} alt='disable-star' />}
            </>;
        })}
        ({value})
    </>;
}

export default RatingStars;

import { Navigate } from 'react-router-dom';

import { ROUTES } from '../helpers';

export function PrivateRoute({ children, isLogin }: any) {
  return isLogin ? children : <Navigate to={ROUTES.LOGIN} />;
}

export function PublicRoute({ children, isLogin, defaultRoute }: any) {
  return isLogin ? <Navigate to={defaultRoute} /> : children;
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { PAGE_PER_RECORD_OPTIONS, PER_PAGE_RECORDS } from '../../../../helpers';
import { ReactSelect } from '../../../components';

interface paginationType {
  page?: number;
  limit?: number;
}

interface Props {
  activePage: number;
  totalCount: number;
  onPageChange: (data?: paginationType) => void;
};

const Pagination = ({
  activePage,
  totalCount,
  onPageChange,
}: Props) => {

  const [perPageRecords, setPerPageRecords] = React.useState(PER_PAGE_RECORDS);

  const pageList = React.useMemo(() => {
    const list: Array<number> = [];
    let i = Math.ceil(totalCount / perPageRecords);
    while (i > 0) {
      list.push(i);
      i -= 1;
    }
    return list.reverse();
  }, [totalCount, perPageRecords]);

  const handleOnPageChange = React.useCallback(
    (page: number, ppl?: number) => {
      if (activePage === page && !ppl) {
        return;
      }
      onPageChange({ page, limit: ppl || perPageRecords });
    },
    [activePage, perPageRecords, onPageChange]
  );

  if (totalCount <= PER_PAGE_RECORDS) {
    return null;
  }

  const firstRecordIndex = (activePage - 1) * perPageRecords;

  return (
    <div className="page">
      <div className="pagination-wrapper d-flex align-item-center">
        <div className="pagination-list d-flex align-item-center">
          <ul className="pagination">
            <li className="page-item">
              <button
                className="pagination-link"
                disabled={activePage === 1}
                onClick={() => handleOnPageChange(activePage - 1)}
              >
                &laquo;
              </button>
            </li>
            {!!pageList.length && <li className="page-item">
              <button className='page-link' disabled>
                {activePage}
              </button>
            </li>}
            <li className="page-item">
              <button className="pagination-link"
                disabled={activePage === pageList[pageList.length - 1]}
                onClick={() => handleOnPageChange(activePage + 1)}
              >
                &raquo;
              </button>
            </li>
          </ul>
        </div>
        <div className="per-page d-flex align-item-center">
          <div className="number-box">
            <div className="react-select">
              <ReactSelect
                options={PAGE_PER_RECORD_OPTIONS.filter(op => op.value <= totalCount + perPageRecords)}
                value={PAGE_PER_RECORD_OPTIONS.find(op => op.value === perPageRecords)}
                onSelect={(option) => {
                  if (perPageRecords !== option.value) {
                    setPerPageRecords(option.value);
                    handleOnPageChange(1, option.value);
                  }
                }}
                isDropdownIndicator={false}
                menuPlacement='top'
              />
            </div>
          </div>
          <div className="values">
            <span>{firstRecordIndex + 1}</span>-<span>{firstRecordIndex + perPageRecords - (firstRecordIndex + perPageRecords < totalCount ? 0 : firstRecordIndex + perPageRecords - totalCount)}</span>
            <span className="of">of</span>
            <span>{totalCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  activePage: 1,
  totalCount: 0,
  onPageChange: () => { },
};

export default Pagination;

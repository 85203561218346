import React from "react";

import { sortingFilterType } from '../Types';
import { copyObject } from '../../utils';

const initialSortingFilterDetail: sortingFilterType = {
    sortBy: "created",
    sortOrder: -1,
};

interface filtersType {
    sortBy: string,
    sortOrder: number,
}

export function useSortingFilters(filters?: filtersType) {
    const [sortingFilters, setSortingFilters] = React.useState<sortingFilterType>(
        copyObject(filters || initialSortingFilterDetail),
    );

    const handleSortingFilterOnChange = React.useCallback((filters: sortingFilterType) => {
        setSortingFilters(filters);
    }, []);

    return { sortingFilters, handleSortingFilterOnChange };
}

import PropTypes from "prop-types";
import { FC, useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideClick(ref: any, { isOpen, onElementClick }: any) {

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (isOpen && !event.target.getAttribute('data-side-bar-menu-btn')) {
                    onElementClick(false);
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, ref, onElementClick]);
}

/**
 * Component that alerts if you click outside of it
 */
export const ViewOutsideClick: FC<any> = (props: any) => {
    const wrapperRef = useRef(null);

    useOutsideClick(wrapperRef, props);

    return <div ref={wrapperRef} {...props}> {props.children} </div>;
};

ViewOutsideClick.propTypes = {
    children: PropTypes.element.isRequired,
    onElementClick: PropTypes.func.isRequired,
};

import React from "react";
import * as Yup from "yup";

import { messages } from "../../../../helpers";
import CancelReasonModalComponent from "./Component";

interface Props {
  isOpen: boolean;
  onClose: any;
  reason: any;
  type: any;
}

const Schema = Yup.object().shape({
  message: Yup.string().required(messages.IS_REQUIRED("Message")),
});

function ShowCancelReasonModal({
  isOpen,
  onClose,
  reason,
  type,
}: //   onFetchReviews,
Props) {
  return (
    <CancelReasonModalComponent
      isOpen={isOpen}
      schema={Schema}
      onClose={onClose}
      reason={reason}
      type={type}
      //   onFetchReviews={onFetchReviews}
    />
  );
}

export default ShowCancelReasonModal;

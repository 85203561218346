import { Dialog, DialogTitle } from "@mui/material";
import { useHideOverflow } from "../../../../common/custom-hooks";

interface Props {
  isOpen: boolean;
  schema: any;
  onClose: any;
  reason: any;
  type: any;
}

function CancelReasonModalComponent({
  isOpen,
  schema,
  onClose,
  reason,
  type,
}: Props) {
  useHideOverflow(isOpen);

  return (
    <div className="msg-popup">
      {/* Add your pop-up content here */}
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {type}
        </DialogTitle>

        <>
          <DialogTitle id="alert-dialog-title">{reason}</DialogTitle>
        </>
      </Dialog>
      {/* Close button */}
      {/* <button onClick={closeMsgPopup}>Close</button> */}
    </div>
  );
}

export default CancelReasonModalComponent;

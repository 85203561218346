import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { adminLogin } from '../../services/auth';
import { User } from '../../types';
import { PERMISSION_TYPE } from '../../../pages/sub-admin/Types';

const initialState: User = {
  accessToken: '',
  id: '',
  profilePicture: '',
  fullName: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  type: '',
  status: '',
  permissions: []
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /** Clearing redux state */
    clearReduxState() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },
    setAdminProfile: (state, action: PayloadAction<any>) => {
      state.fullName = action.payload.fullName;
      state.profilePicture = action.payload.profilePicture;
    },
  },
  extraReducers: (builder) => {
    /** Set admin detail after login */
    builder.addCase(
      adminLogin.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { data = {} } = action.payload;
        state.accessToken = data.accessToken;
        state.id = data._id;
        state.profilePicture = data.profilePicture;
        state.firstName = data.firstname;
        state.lastName = data.lastname;
        state.fullName = ((data.firstname || '') + ' ' + (data.lastname || '')).trim();
        state.phoneNumber = data.phoneNumber;
        state.email = data.email;
        state.type = data.userType;
        state.status = data.status;
        state.permissions = data.role?.permission?.filter((per: PERMISSION_TYPE) => per.activeInactive) || [];
      },
    );
  },
});

export const { clearReduxState, setAdminProfile } = authSlice.actions;
export default authSlice.reducer;

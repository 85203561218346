import React from "react";

import { fetchUserPlans } from "../../redux/services";
import { useAppDispatch, useHandleCalls } from "../custom-hooks";
import { userPlanType } from "../Types";

function useGetUserPlans() {
    const dispatch = useAppDispatch();

    const [userPlans, setUserPlans] = React.useState<Array<userPlanType>>([]);

    const handleUserPlans = React.useCallback(() => {
        dispatch(fetchUserPlans({
            callback: (res: any) => {
                console.log("plannn",res)
                if (res?.data?.length) {
                    const monthly_plans: Array<userPlanType> = [];
                    const yearly_plans: Array<userPlanType> = [];
                    const Free: Array<userPlanType> = [];
                    res.data.forEach((plan: any) => {
                        if (!(plan?.nickname) || (plan.name && plan.name.toLowerCase() === 'gold')) return null;
                        if (plan.interval === 'month') {
                            monthly_plans.push({ label: plan.name, value: plan.id, metaData: plan });
                        } else if (plan.interval === 'year') {
                            yearly_plans.push({ label: plan.name, value: plan.id, metaData: plan });
                             // yearly_plans.push({ label: plan.name, value: plan.id, metaData: {..plan,interval:"free"} });
                        }
                    });
                    setUserPlans([
                        { label: 'Annual', value: '', options: [yearly_plans[0]] },
                        { label: 'Monthly', value: '', options: [monthly_plans[0]] },
                        { label: 'Free', value: '', options: Free }
                    ]);
                }
            }
        }));
    }, [dispatch]);

    useHandleCalls(handleUserPlans, [], 'userPlans');

    return userPlans;
}

export default useGetUserPlans;

import React from 'react';

import { useAppSelector } from '../../custom-hooks';

interface Props {
  isComponentLoading: boolean;
}

function LoaderView({ isComponentLoading }: Props) {
  const { isLoading } = useAppSelector((state: any) => state.common);

  const isFetching = React.useMemo(
    () => isLoading || isComponentLoading,
    [isLoading, isComponentLoading],
  );

  if (!isFetching) {
    return null;
  }

  return (
    <div className="loader-parent">
      <div className="loader"></div>
    </div>
  );
}

LoaderView.defaultProps = {
  isComponentLoading: false,
};

export default React.memo(LoaderView);

import React, { Suspense } from 'react';
import { Route, Routes as BrowserRoutes } from 'react-router-dom';

import { useAppSelector } from '../common/custom-hooks';
import { ADMIN_USER_TYPE, ROUTES } from '../helpers';
import { PERMISSION_TYPE } from '../pages/sub-admin/Types';
import { PrivateRoute, PublicRoute } from './privateRoute';
import { commonRoutes, pageRoutes } from './routes';
import { RouteType } from './types';

function RoutesWrapper() {
  const { accessToken, permissions, type } = useAppSelector((state: any) => state.auth);

  const routeList = React.useMemo(() => {
    let finalList: Array<RouteType> = [];
    if (type === ADMIN_USER_TYPE) {
      finalList = pageRoutes.slice();
    } else {
      const finalRoutes: Array<any> = pageRoutes.map((route: RouteType) => {
        const permission = permissions.find((item: PERMISSION_TYPE) => item.module.toLowerCase() === route.permission?.toLowerCase());
        if (permission) {
          return route;
        }
        return null;
      }).filter(route => !!route);
      if (!!finalRoutes.length) {
        finalList = finalRoutes.filter(route => !!route);
      }
    };
    return [...finalList, ...commonRoutes];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Suspense>
      <BrowserRoutes>
        {routeList.map((route: RouteType, index) => {
          const WrapperRoute = route.isPrivate ? PrivateRoute : PublicRoute;
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <WrapperRoute isLogin={!!accessToken} defaultRoute={routeList[0]?.path || ROUTES.ACCOUNTS}>
                  <route.Component />
                </WrapperRoute>
              }
            />
          );
        })}
      </BrowserRoutes>
    </Suspense>
  );
}

export default RoutesWrapper;
